import React from 'react';
import Button from '~/src/components/Button';
import ContentContainer from '~/src/components/ContentContainer';
import { Urls } from '~/src/lib/enums/urls';
import { Banner } from '~/src/models/Banner';
import Picture from '~/src/overtime-lib/src/www/components/Picture';
import useFetch from '~/src/overtime-lib/src/www/hooks/useFetch';
import './Banner.scss';

const Banner = ({ site }: { site: string }) => {
	const banners = useFetch<Banner[]>({
		url: `${Urls.ApiRestBannersUrl}?site=${site}`,
		key: 'banners',
	});

	const banner = banners.result.read()?.at(0);
	if (!banner) {
		return <></>;
	}

	return (
		<div className="Banner">
			<ContentContainer>
				<div className="Container">
					<a className="ImageContainer" title={banner.title} href={banner.url}>
						<Picture alt={banner.title} src={banner.image_path} width={850} mobileWidth={800} />
					</a>
					<div className="Content">
						<h2>{banner.title}</h2>
						<p>{banner.text}</p>
						{banner.url && banner.url_text && (
							<div className="ButtonsContainer">
								<Button href={banner.url}>{banner.url_text}</Button>
							</div>
						)}
					</div>
				</div>
			</ContentContainer>
		</div>
	);
};

export default Banner;
