import _ from 'lodash';
import React from 'react';
import Suspended from '~/src/components/Suspended';
import { Urls } from '~/src/lib/enums/urls';
import { OteTeamStanding } from '~/src/models/OteTeamStandings';
import useFetch from '~/src/overtime-lib/src/www/hooks/useFetch';
import { colorFromInt } from '~/src/overtime-lib/src/www/lib';
import './Standings.scss';

const Team = ({ ote_team_standing }: { ote_team_standing: OteTeamStanding }) => {
	const ote_team = ote_team_standing.ote_seasons_ote_team?.ote_team;
	let instagramHandle = null;
	try {
		instagramHandle = ote_team.instagram_url
			? new URL(ote_team.instagram_url)?.pathname.split('/')[1]?.toLowerCase()
			: null;
	} catch {
		// If the instagram URL is invalid, we cannot get the handle, so leave it as null
	}

	return (
		<div className="Team">
			<div className="Rank">{ote_team_standing.position ?? '-'}</div>
			<a className="inherit" href={`/teams/${ote_team.slug}`}>
				{ote_team.logo_path ? (
					<img alt={ote_team.name} src={`https://images.overtime.tv/${ote_team.logo_path}?width=96&format=webp`} />
				) : (
					<div className="LogoPlaceholder" style={{ backgroundColor: colorFromInt(ote_team.primary_color) }} />
				)}
			</a>
			<div className="NameAndOrg">
				<div className="Name">
					<a className="inherit Team" href={`/teams/${ote_team.slug}`}>
						{ote_team.name}
					</a>
				</div>
				<div className="Instagram">
					{instagramHandle ? (
						<a className="inherit" href={ote_team.instagram_url}>
							@{instagramHandle}
						</a>
					) : null}
				</div>
			</div>
			<div className="Record">
				<div>{ote_team_standing.wins ?? 0}</div>
				<div>-</div>
				<div>{ote_team_standing.losses ?? 0}</div>
			</div>
		</div>
	);
};

const _Standings = () => {
	const fetchResult = useFetch<OteTeamStanding[]>({
		url: `${Urls.ApiRestOteTeamStandingsCurrentUrl}`,
		key: 'ote_team_standings',
	});
	const ote_team_standings = fetchResult?.result.read();

	return (
		<>
			{_.sortBy(ote_team_standings, [
				(s) => s.position,
				(s) => s.wins * -1,
				(s) => s.losses,
				(s) => s.ote_seasons_ote_team.ote_team.name,
			]).map((s, i) => (
				<Team key={s.id} ote_team_standing={s} />
			))}
		</>
	);
};

const Standings = ({}) => {
	return (
		<div className="IndexStandings">
			<h1>Standings</h1>
			<Suspended>
				<_Standings />
			</Suspended>
		</div>
	);
};

export default Standings;
