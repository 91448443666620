import _, { sortBy } from 'lodash';
import React, { useContext } from 'react';
import ContentContainer from '~/src/components/ContentContainer';
import Ote from '~/src/components/Ote';
import Suspended from '~/src/components/Suspended';
import { Urls } from '~/src/lib/enums/urls';
import { OteGame, isLive, isScheduled } from '~/src/models/OteGame';
import { OteTeam } from '~/src/models/OteTeam';
import MobileMenu, { MobileMenuContext } from '~/src/overtime-lib/src/www/components/MobileMenu';
import useFetch from '~/src/overtime-lib/src/www/hooks/useFetch';
import { capitalize } from '~/src/overtime-lib/src/www/lib';
import './Header.scss';

const MobileMenuItems = ({ showSchedule = true }: { showSchedule?: boolean }) => {
	const fetchResult = useFetch<OteTeam[]>({
		url: `${Urls.ApiRestOteTeamsCurrentUrl}`,
		key: 'ote_teams',
	});
	const teams = fetchResult?.result.read();

	const { isVisible } = useContext(MobileMenuContext);

	const links = [
		['teams', 'All Teams'],
		showSchedule ? ['schedule'] : null,
		['scores'],
		['players'],
		['standings'],
		['statistics', 'Stats'],
		[Urls.BeehiivOteUrl, 'News'],
		['about'],
	].filter(Boolean);

	return (
		<nav className="MobileMenuItems">
			<div className="MenuTeams">
				{sortBy(teams, 'name').map((t, i) => (
					<a key={t.id} href={`/teams/${t.slug}`} tabIndex={!isVisible ? -1 : 3 + i} className="Team">
						<img alt={t.name} src={`https://images.overtime.tv/${t.wordmark_image_path}`} />
					</a>
				))}
			</div>
			{links.map((l) => (
				<a href={`${l[0].includes('/') ? '' : '/'}${l[0]}`} key={l[0]} tabIndex={!isVisible ? -1 : null}>
					{l[1] ?? capitalize(l[0])}
				</a>
			))}
		</nav>
	);
};

const Header = () => {
	const fetchGames = useFetch<OteGame[]>({
		url: `${Urls.ApiRestOteGamesUrl}?all=true`,
		key: 'ote_games',
	});
	const games = fetchGames.result.read();

	const filteredGames = _.orderBy(
		games?.filter((g) => isScheduled(g) || isLive(g)),
		['starts_at', 'id'],
		['asc', 'asc'],
	);

	const showSchedule = filteredGames?.length > 0;

	return (
		<header className="Header">
			<div className="Background" />
			<ContentContainer>
				<div className="HeaderContainer">
					<a href="/" title="Home" tabIndex={1}>
						<Ote height={14} width={null} color="#33FFFF" />
					</a>
					<div className="LinksContainer">
						<div role="navigation" className="DesktopLinks">
							<a className="inherit" href="/teams" tabIndex={2}>
								Teams
							</a>
							<div>|</div>
							{showSchedule ? (
								<>
									{' '}
									<a className="inherit" href="/schedule">
										Schedule
									</a>
									<div>|</div>
								</>
							) : null}
							<a className="inherit" href="/scores">
								Scores
							</a>
							<div>|</div>
							<a className="inherit" href="/standings">
								Standings
							</a>
							<div>|</div>
							<a className="inherit" href="/players">
								Players
							</a>
							<div>|</div>
							<a className="inherit" href="/statistics">
								Stats
							</a>
							<div>|</div>
							<a className="inherit" href={Urls.BeehiivOteUrl}>
								News
							</a>
							<div>|</div>
						</div>
						<a className="inherit" href={Urls.VivenuTicketsUrl}>
							Tickets
						</a>
						<div>|</div>
						<a className="inherit Shop" href={Urls.ShopifyCollectionOteUrl}>
							Shop
						</a>
					</div>
					<MobileMenu>
						<Suspended>
							<MobileMenuItems showSchedule={showSchedule} />
						</Suspended>
					</MobileMenu>
				</div>
			</ContentContainer>
		</header>
	);
};
export default Header;
